<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput  v-model="plate_no" placeholder="Plate No."/>
        </CCol>
        <CCol lg="3">
          <CInput  v-model="asset_no" placeholder="Asset No."/>
        </CCol>
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="vehicleTypeList.data"
            :reduce="item => item.id"
            v-model="vehicle_type_id"
            placeholder="Vehicle Type"
          > 
          </v-select> 
        </CCol> 
         
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol>
      </CRow>
      <CRow class="mb-3">
        <CCol lg="3" v-if="!JSON.parse(window.localStorage.getItem('user_data')).depot_id">
          <v-select 
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            v-model="location"
            placeholder="Depot"
          > 
          </v-select>  
        </CCol> 
        <CCol lg="3" v-else>
          <v-select 
            disabled
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            :value="JSON.parse(window.localStorage.getItem('user_data')).depot_id"
            placeholder="Depot"
          > 
          </v-select>  
        </CCol> 
        <CCol lg="3">
          <v-select 
            label="company_owned" 
            :options="[
            	{
            		company_owned: 'Yes',
            		id: '1'
            	},
            	{
            		company_owned: 'No',
            		id: '0'
            	},
            	
            ]"
            :reduce="item => item.id"
            v-model="company_owned"
            placeholder="Company Owned"
          > 
          </v-select>  
        
        </CCol>
        <CCol lg="3">
         <v-select 
            label="vendor_name" 
            :options="subconList.data"
            :reduce="item => item.id"
            v-model="vendor_id"
            placeholder="Subcon"
          > 
          </v-select>
        </CCol>      
        <CCol lg="1">
        
        </CCol>    
        <CCol lg="2" class="float-right">
          <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="3">
          <v-select 
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="project_id"
            placeholder="Project/Dept"
          > 
          </v-select>  
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../config.js';
  import axios from '../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
  
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            window,
            plate_no: "",
            asset_no: "",
            vehicle_type_id: "",
            maker_model_id: "",
            location: "",
            year: "", 
            project_id: "",
            vehicleTypeList: {
            	data: []
            },
      		
            customerList: {
              data: []
            },
            depotList: {
              data: []
            },
             subconList: {
            	data: []
            },
            vendor_id : "",
            company_owned : ""
          }
        },
      mounted() {
        this.getVehicleType();
        this.getCustomer();
        this.getDepot();
         this.getSubcon();
      },
      components: {vSelect},
      methods: {
        search() {
          const data = {
            plate_no: this.plate_no,
            asset_no: this.asset_no,
            vehicle_type_id: this.vehicle_type_id,
            maker_model_id: this.maker_model_id,
            year: this.year, 
            location: this.location,
            project_id: this.project_id,
            company_owned : this.company_owned,
            vendor_id : this.vendor_id
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.plate_no = "";
          this.asset_no = "";
          this.vehicle_type_id = "";
          this.maker_model_id = "";
          this.year = ""; 
          this.location = ""
          this.project_id = ""
          this.company_owned = ""
          this.vendor_id = ""
          const data = {
            plate_no: this.plate_no,
            asset_no: this.asset_no,
            vehicle_type_id: this.vehicle_type_id,
            maker_model_id: this.maker_model_id,
            year: this.year, 
            location: this.location,
            project_id: this.project_id
          }
          this.$emit("depot-search-query", data)
        },

        getVehicleType(){

          axios.get(config.api_path+'/reference/settings-list',{
				params:{
					setting_type:'vehicle_type'
					}
						})
				.then(response => {
					this.vehicleTypeList = response.data; 
				})

	    },
       getSubcon(){
        axios.get(config.api_path+'/reference/vendors-list',{
				params:{
				
					}
						})
				.then(response => {
					this.subconList = response.data; 
				})

	    },

      getCustomer(){

        axios.get(config.api_path+'/reference/customer-list', {
            params: {
              show_all : true
            }
          })
	    	.then(response => {
	    		this.customerList = response.data; 
	    	})

      },
      getDepot(){
        axios.get(config.api_path+'/reference/settings-list',{
				params:{
					setting_type:'depot'
					}
						})
				.then(response => {
					this.depotList = response.data; 
				})

      },

      }
    }
</script>

<style scoped>

</style>
