<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Vehicle Reports ({{dataList.total}})</h5>
			</CCol>
	     		 <CCol lg="6">
					  <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
							v-if="config.getPermission('vehicle_reports').download" 
							size="sm" style="margin-right: 10px" 
							color="primary" 
							@click="download"
							class="float-lg-right">
							<i class="fa fa-download">
							</i>Download
					</CButton>

		      		<!-- <CButtonGroup size="sm" class="float-lg-right" v-if="config.getPermission('vehicle').create">
			          <CButton color="success" href="#/data/create_vehicle"><i class="fa fa-plus"></i> Add Vehicle</CButton>
			          <CButton :class="showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        </CButtonGroup>  -->
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	       
		      <CCol lg="12" >  
				   <div class="table-responsive-sm table-responsive-md"	>
	      		<CTabs variant="pills" :active-tab="0">
	      			<CTab title="All" @click.native="changeStatus('')">
				    	<br/>
				      	<CDataTable
						   :loading="isLoading"
					      	:items="dataList.data"
							:fields="company_type == 'trucking' ? fields_2 : fields"
							hover
							striped
							outlined
							
							
					    >  
					    		<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
							</template>
					      	<!-- <template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('vehicle').view" @click="viewModal(item)">View</CDropdownItem> 
						            	<CDropdownItem @click="viewModal(item)">Assign</CDropdownItem>
						            	<CDropdownItem @click="viewModal(item)">Update Odometer</CDropdownItem>  
						                <CDropdownItem v-if="config.getPermission('vehicle').update" @click="updateModal(item)">Edit</CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('vehicle').delete" @click="deleteRow(item)">Delete</CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template> -->
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPage"
				          	:pages="Math.ceil(dataList.total / 20)"
				          	:activePage="currentPage"
				          	@update:activePage="updatePage"
				        />
				    </CTab>
				    <CTab title="Active" @click.native="changeStatus('active')">
				    	<br/>
				      	<CDataTable
						  :loading="isLoading"
					      	:items="dataListActive.data"
							:fields="company_type == 'trucking' ? fields_2 : fields"
							hover
							striped
							outlined
							
					    >  
					    		<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
							</template>
					      	<!-- <template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('vehicle').view" @click="viewModal(item)">View</CDropdownItem> 
						            	<CDropdownItem @click="viewModal(item)">Assign</CDropdownItem>
						            	<CDropdownItem @click="viewModal(item)">Update Odometer</CDropdownItem>  
						                <CDropdownItem v-if="config.getPermission('vehicle').update" @click="updateModal(item)">Edit</CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('vehicle').delete" @click="deleteRow(item)">Delete</CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template> -->
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageActive"
				          	:pages="Math.ceil(dataListActive.totActiveal / 20)"
				          	:activePage="currentPageActive"
				          	@update:activePage="updatePageActive"
				        />
				    </CTab>
				    <CTab title="Inactive" @click.native="changeStatus('inactive')">
				    	<br/>
				      	<CDataTable
						  :loading="isLoading"
					      	:items="dataListInactive.data"
							:fields="company_type == 'trucking' ? fields_2 : fields"
							hover
							striped
							outlined
							
					    >  
					    		<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
							</template>
					      	<!-- <template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('vehicle').view" @click="viewModal(item)">View</CDropdownItem> 
						            	<CDropdownItem @click="viewModal(item)">Assign</CDropdownItem>
						            	<CDropdownItem @click="viewModal(item)">Update Odometer</CDropdownItem>  
						                <CDropdownItem v-if="config.getPermission('vehicle').update" @click="updateModal(item)">Edit</CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('vehicle').delete" @click="deleteRow(item)">Delete</CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template> -->
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageInactive"
				          	:pages="Math.ceil(dataListInactive.total / 20)"
				          	:activePage="currentPageInactive"
				          	@update:activePage="updatePageInactive"
				        />
				    </CTab> 
				</CTabs> 
				   </div>
	      	</CCol>
	    </CRow>
	
		</CCard>
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './vehicle_search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import '../style.css';
import moment from 'moment';

export default {
	mounted(){ 
		this.getData();
		this.getData('active');
		this.getData('inactive');
		this.getDepot();
		
	},
	data(){
		return{
			company_type: JSON.parse(window.localStorage.getItem("user_data")).company.company_type.includes("Trucking") ? "trucking" : JSON.parse(window.localStorage.getItem("user_data")).company.company_type,
			config,
			isLoading: false,
			formModal: false,
			showViewModal: false,
			dataList: [],
			dataListActive: [],
			dataListInactive: [],
			depotName: "",
			dataParams: {
				
			},
			fields: [
				{
					key: 'plate_no', 
					label: 'Plate No.'
				},
				{
					key: 'asset_no', 
					label: 'Asset No.'
				},
				
				// {
				// 	key: 'body_no', 
				// 	label: 'Body No.'
				// },
				
				{
					key: 'vehicle_type_id_label', 
					label: ' Vehicle Type'
				},
				{
					key: 'maker_model_id_label', 
					label: 'Maker'
				},
				
				{
					key: 'current_location', 
					label: ' Current Location '
				},
				{
					key: 'depot_id_label', 
					label: ' Depot '
				},
				{
					key: 'project_label', 
					label: ' Project/Dept '
				},


				
				// {
				// 	key: 'model_id_label', 
				// 	label: 'Model'
				// },
				// s
				// {
				// 	key: 'with_gps_label', 
				// 	label: 'With GPS'
				// },
				{
					key: 'company_owned_label', 
					label: 'Company Owned'
				},
				{
					key: 'vendor_label', 
					label: 'Subcon'
				},
				{
					key: 'status', 
					label: 'Status'
				}, 
				
			],
			fields_2: [
				{
					key: 'plate_no', 
					label: 'Plate No.'
				},
				

				{
					key: 'vehicle_type_id_label', 
					label: ' Vehicle Type'
				},
				{
					key: 'depot_id_label', 
					label: ' Depot '
				},
				
				{
					key: 'maker_model_id_label', 
					label: 'Maker'
				},
				// {
				// 	key: 'model_id_label', 
				// 	label: 'Model'
				// },
				// s
				{
					key: 'with_gps_label', 
					label: 'With GPS'
				},
				{
					key: 'company_owned_label', 
					label: 'Company Owned'
				},
				{
					key: 'vendor_label', 
					label: 'Subcon'
				},
				{
					key: 'status', 
					label: 'Status'
				}, 
				
			],
			currentPage: 1,
			currentPageActive: 1,
			currentPageInactive: 1,
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				plate_no: "",
				asset_no: "",
				maker_model_id: "",
				vehicle_type_id: "",
				year: "",
				location: "",
				project_id: "",
				company_owned : "",
				vendor_id: ""
			},
			
			config,
			vehicle_status: null
		}
	},
	name: 'Tables',
	components: { Search, Datepicker, vSelect},
	
	methods: {
		changeStatus(status) {
			this.vehicle_status = status
		},
		
		 getBadge (status) {
			status = status.toLowerCase();
    	return status === 'active' ? 'success'
             : status === 'inactive' ? 'danger' : 'primary'
    },
		updatePage(data){
			this.currentPage = data;
			this.getData();
		}, 

		updatePageActive(data){
			this.currentPageActive = data;
			this.getData('active');
		}, 

		updatePageInactive(data){
			this.currentPageInactive = data;
			this.getData('inactive');
		}, 

	    toggleModal(){
	    	this.formModal = !this.formModal;
	    },


	    search(event){
	    	this.filter = event; 
			this.getData();
			this.getData('active');
			this.getData('inactive');
	    },

	    getData(type = ''){  
		this.isLoading = true;
	    	let maker_model_id = this.filter.maker_model_id;
	    	if(maker_model_id == "all" || maker_model_id == null){
	    		maker_model_id = ""
	    	}
	    	let vehicle_type_id = this.filter.vehicle_type_id;
	    	if(vehicle_type_id == "all" || vehicle_type_id == null){
	    		vehicle_type_id = ""
	    	} 
	    	let project_id = this.filter.project_id;
	    	if(project_id == "all" || project_id == null){
	    		project_id = ""
	    	} 
	    	let location = this.filter.location;
	    	if(location == "all" || location == null){
	    		location = ""
	    	}

			let vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "all" || vendor_id == null){
	    		vendor_id = ""
	    	}

			let status = type;

			axios.get(`${config.api_path}/vehicle`,{
				params : {
					plate_no : this.filter.plate_no,
					asset_no : this.filter.asset_no,
					year : this.filter.year,
					maker_model_id,
					vehicle_type_id,
					location,
					project_id,
					page: this.currentPage,
					limit:20,
					vendor_id,
					company_owned : this.filter.company_owned,
					status
				}
			})
	    	// axios.get(config.api_path+'/vehicle?plate_no='
			// 	+this.filter.plate_no+'&body_no='+this.filter.body_no+'&year='
			// 	+this.filter.year+'&maker_model_id='+maker_model_id+'&vehicle_type_id='+vehicle_type_id+'&location='+location+'&project_id='+project_id+'&page='+this.currentPage+'&limit=20')
	    	// .
			.then(response => {
				let data = response.data;
				data.data = data.data.map((value, index)=>{
	    			value.company_owned_label = "N/A";
	    			if(value.company_owned == "1" || value.company_owned == 1){
	    				value.company_owned_label = "Yes";
	    			}
	    			else{
	    				value.company_owned_label = "No";
	    			}

	    			if(value.vehicle_type_id){
	    				value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			}
					
	    			if(value.with_gps){
	    				value.with_gps_label = "Yes";
	    			}
	    			else{
	    				value.with_gps_label = "No";
	    			}
					if(value.location_label == null){
	    				value.depot_id_label = " ";
	    			}
					else{
	    				value.depot_id_label = value.location_label.setting_name;
	    			}
					// value.location_id_label = " ";
	    			// if(value.location_label){
	    			// 	value.location_id_label = value.location_label.setting_name
	    			// }

					
					value.vendor_label = "" 
	    			if(value.vendor){
	    				value.vendor_label = value.vendor.vendor_name;
					}

	    			value.project_label = "";
	    			if(value.project){
	    				value.project_label = value.project.customer_name
	    			}

					value.maker_model_id_label = value?.maker_model?.setting_name ?? '';
					value.body_no = value.body_no ?? '';
					value.current_location = value?.current_location?.customer_name ?? '';
					value.asset_no = value?.asset_no ?? '';

	    			return value;
	    		}); 
	    		
				switch(type) {
					case 'active':
						this.dataListActive = {...data};
						break;
					case 'inactive':
						this.dataListInactive = {...data};
						break;
					default:
						this.dataList = {...data}
						break;
				}
				
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	   
	    download(){
	    	var tab = document.querySelector('.nav-link.active').innerHTML;

			let project_id = this.filter.project_id;
	    	if(project_id == "all" || project_id == null){
	    		project_id = ""
	    	} 

	    	var maker_model_id = this.filter.maker_model_id;
	    	if(maker_model_id == "all" || maker_model_id == null){
	    		maker_model_id = ""
	    	}
	    	var vehicle_type_id = this.filter.vehicle_type_id;
	    	if(vehicle_type_id == "all" || vehicle_type_id == null){
	    		vehicle_type_id = ""
	    	}

			let vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "all" || vendor_id == null){
	    		vendor_id = ""
	    	}

			let location = this.filter.location;
	    	if(location == "all" || location == null){
	    		location = ""
	    	}
			let status = this.vehicle_status

			this.$showLoading(true)
			axios.get(`${config.api_path}/reports/vehicle`,{
				params : {
					vendor_id,
					plate_no : this.filter.plate_no,
					asset_no : this.filter.asset_no,
					year : this.filter.year,
					maker_model_id,
					vehicle_type_id,
					company_owned : this.filter.company_owned,
					location,
					project_id,
					status
				}
			})
	    	// axios.get(config.api_path+'/reports/vehicle?vendor_id='+vendor_id+'&
			// plate_no='+this.filter.plate_no+'&body_no='+this.filter.body_no+'&
			// year='+this.filter.year+'&maker_model_id='+maker_model_id+'&
			// vehicle_type_id='+vehicle_type_id)
	    	.then(response=>{
				this.$showLoading(false)
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 

				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
	    	})
			.catch(err => {
				this.$showLoading(false)
			})
	    }

 	}
}
</script>
